import React from "react";

export default function SearchItemPart({ items }) {
  const [showMore, setShowMore] = React.useState(false);
  return items.length < 2 ? (
    items.map((item, index) => (
      <div key={index} className="flex items-center">
        <hr className="mr-1 rotate-90 w-4" />
        <span>{item.from.value}</span>
        <i className="fa-solid fa-arrow-right-long mx-2"></i>
        <span>{item.to.value}</span>
      </div>
    ))
  ) : showMore ? (
    items.map((item, index) => (
      <div key={index} className="flex items-center">
        <hr className="mr-1 rotate-90 w-4" />
        <span>{item.from.value}</span>
        <i className="fa-solid fa-arrow-right-long mx-2"></i>
        <span>{item.to.value}</span>
      </div>
    ))
  ) : (
    <div className="flex items-center">
      <hr className="mr-1 rotate-90 w-4" />
      <span>{items[0].from.value}</span>
      <i className="fa-solid fa-arrow-right-long mx-2"></i>
      <span>{items[0].to.value}</span>
      <button
        className="ms-1 hover:bg-[#ffffff]/30 px-1 rounded flex gap-1 px-1 py-2"
        onClick={(e) => {
          e.stopPropagation();
          setShowMore(true);
        }}
      >
        <span className="w-1 h-1 bg-[#ffffff] rounded"></span>
        <span className="w-1 h-1 bg-[#ffffff] rounded"></span>
        <span className="w-1 h-1 bg-[#ffffff] rounded"></span>
      </button>
    </div>
  );
}
