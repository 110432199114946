import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeSelector from "./TimeSelector";

export default function TimeInput({
  label,
  formKey,
  formValue,
  onSelectValue,
}) {
  return (
    <div className="">
      <ReactDatePicker
        selected={formValue}
        onChange={(value) => {
          onSelectValue(formKey, value);
        }}
        customInput={<TimeSelector label={label} />}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm a"
      />
    </div>
  );
}
