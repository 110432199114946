import axios from "axios";
import React, { useState } from "react";
import Select, { components } from "react-select";

// Custom Option component to display icon, label, and sub-label
const Option = ({ data, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="flex gap-2 items-center">
        {/* Display icon */}
        <span className="bg-primary-100 p-2 rounded">
          <i className="fa-solid fa-map-pin"></i>
        </span>
        <div>
          <p className="text-sm">{data.hotel_name}</p> {/* Display label */}
          <p className="text-xs">
            {data.city}, {data.country}
          </p>{" "}
          {/* Display sub-label */}
        </div>
      </div>
    </components.Option>
  );
};

export default function HotelSelectionDropdown({
  selectRef,
  formKey,
  onSelectValue,
  toggleShowDropdown,
  focusIndex,
  setCurrentFocusIndex,
}) {
  const [selectedOption, setSelectedOption] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const onChangeValue = (option) => {
    setSelectedOption(option);
    onSelectValue(formKey, option);
    toggleShowDropdown();
    setCurrentFocusIndex(focusIndex + 1);
  };

  const onInputChange = async (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${window.ddSearchForm.hotelServiceURL}${value}`
      );
      const data = response.data;
      const formattedOptions = data.map((item) => ({
        value: item.hotel_name,
        label: `${item.hotel_name} - ${item.city}, ${item.country}`,
        hotel_name: item.hotel_name,
        city: item.city,
        country: item.country,
      }));
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      ref={selectRef}
      className="fixed top-0 left-0 bg-white w-screen h-screen z-[999] p-3 lg:rounded lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-full lg:p-0"
    >
      <div className="text-end">
        <button
          type="button"
          className="inline-block px-2 py-1 lg:hidden"
          onClick={toggleShowDropdown}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <Select
        autoFocus
        value={selectedOption}
        onChange={onChangeValue}
        onInputChange={(inputValue, actionMeta) => {
          if (actionMeta.action === "input-change") {
            onInputChange(inputValue);
          }
        }}
        options={options}
        isLoading={isLoading}
        components={{
          Option,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }} // Use custom components
        menuIsOpen={true}
        placeholder="Search"
        styles={{
          menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: 0,
          }),
        }}
        classNames={{
          control: (state) =>
            state.isDisabled
              ? undefined
              : state.isFocused
              ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
              : undefined,
          option: (state) =>
            state.isDisabled
              ? undefined
              : state.isSelected
              ? "!bg-primary"
              : state.isFocused
              ? "!bg-primary-100"
              : undefined,
        }}
      />
    </div>
  );
}
