import React, { forwardRef } from "react";

const TimeSelector = forwardRef(({ value, onClick, label }, ref) => {
  const pickupTime = value;

  return (
    <div
      ref={ref}
      onClick={onClick}
      className={`bg-primary-100 px-4 py-3 rounded flex justify-between gap-3 cursor-pointer w-full h-full ${
        window.ddSearchForm.formType === "dashboard"
          ? "min-h-[86px]"
          : "min-h-[68px]"
      }`}
    >
      <div className="w-full lg:w-fit">
        <span className="block text-sm text-[#888787] font-light">{label}</span>
        {pickupTime ? (
          <span
            className={`block font-medium text-base ${
              window.ddSearchForm.formType === "dashboard"
                ? "lg:text-xl"
                : "lg:text-base"
            }`}
          >
            {pickupTime}
          </span>
        ) : (
          <span
            className={`block font-medium text-base ${
              window.ddSearchForm.formType === "dashboard"
                ? "lg:text-xl"
                : "lg:text-base"
            }`}
          >
            h:mm a
          </span>
        )}
      </div>
    </div>
  );
});

export default TimeSelector;
