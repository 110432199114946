import React from "react";
import DateRangeInput from "../DateRangeInput";
import HotelSelector from "./HotelSelector";
import NightSelector from "./NightSelector";
import RoomItem from "./RoomItem";
import unixTimeToDate from "../../util/convertUnixToDate";
import getDateOnly from "../../util/getDateOnly";
import { countries } from "../../util/countries";
import Cookies from "../../util/cookies";

export default function Hotels() {
  const [form, setForm] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const countriesList = countries.map((item) => item.name);

  const [roomClicked, setRoomClicked] = React.useState(0);
  const handleRoomToggle = (index) => {
    if (roomClicked === index) {
      return setRoomClicked("0");
    }
    setRoomClicked(index);
  };

  const [showRoomsDropdown, setShowRoomsDropdown] = React.useState(false);
  const roomsDropdownRef = React.useRef(null);

  const [recentSearches, setRecentSearches] = React.useState([]);

  const [currentFocusIndex, setCurrentFocusIndex] = React.useState();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("data");
    if (myParam) {
      setForm(JSON.parse(myParam));
    } else {
      setForm({
        location: {
          id: "",
          hotel_name: "Location",
          city: "(City, Hotel, Location)",
          country: "",
        },
        checkInDate: new Date(),
        checkOutDate: unixTimeToDate(
          new Date().setDate(new Date().getDate() + 1)
        ),
        nights: 1,
        rooms: [
          {
            adults: 1,
            children: [],
            nationality: "Pakistan",
          },
        ],
      });
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        roomsDropdownRef.current &&
        !roomsDropdownRef.current.contains(event.target)
      ) {
        setShowRoomsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [roomsDropdownRef]);

  React.useEffect(() => {
    if (
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
    ) {
      setRecentSearches(
        JSON.parse(
          Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
        )?.hotel
      );
    }
  }, []);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (typeof form.checkInDate === "string") {
      form.checkInDate = getDateOnly(new Date(Date.parse(form.checkInDate)));
    } else {
      form.checkInDate = getDateOnly(form.checkInDate);
    }
    if (typeof form.checkOutDate === "string") {
      form.checkOutDate = getDateOnly(new Date(Date.parse(form.checkOutDate)));
    } else {
      form.checkOutDate = getDateOnly(form.checkOutDate);
    }
    window.location =
      window.ddSearchForm.hotelActionURL + "?data=" + JSON.stringify(form);

    // save search data in cookies
    let temp =
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
        ? JSON.parse(
            Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
          )
        : { flight: [], hotel: [], car: [], insurance: [] };
    // check if the same object does not exist already
    if (
      temp.hotel &&
      !temp.hotel.some((obj) => JSON.stringify(obj) === JSON.stringify(form))
    ) {
      if (temp.hotel && temp.hotel.length > 2) {
        temp.hotel.splice(0, 1);
      }
      temp.hotel.push(form);
      Cookies.setCookie(
        `recentSearches-${window.ddSearchForm.userId}`,
        JSON.stringify(temp)
      );
    }
  };

  const onChangeValues = (key, value) => {
    if (key instanceof Array && value instanceof Array) {
      // if update more than one properties in form
      let temp = {};
      for (let i = 0; i < key.length; i++) {
        temp[key[i]] = value[i];
      }
      if (key.includes("checkOutDate") && value[1]) {
        let _MS_PER_DAY = 1000 * 60 * 60 * 24;
        let a = new Date(value[0]);
        let b = new Date(value[1]);
        // Discard the time and time-zone information.
        let utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        let utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        let n = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        temp["nights"] = n;
      }
      setForm(Object.assign({}, form, temp));
    } else {
      // if update just single property in form
      let temp = {};
      if (key === "nights") {
        let a = new Date(form.checkInDate);
        temp["checkOutDate"] = unixTimeToDate(
          new Date().setDate(a.getDate() + value)
        );
      }
      temp[key] = value;
      setForm(Object.assign({}, form, temp));
    }
  };

  const calculateGuests = () => {
    return form.rooms.reduce((accumulator, curVal) => {
      return (accumulator += curVal.adults + curVal.children.length);
    }, 0);
  };

  const renderRecentSearches = () => {
    if (recentSearches.length < 1) {
      return null;
    }

    return (
      <div className="flex flex-wrap gap-2 items-center">
        {" "}
        {/* Use div instead of React.Fragment */}
        <span className="font-medium">Recent Searches</span>
        {recentSearches.length &&
          recentSearches.map((item, index) => {
            return (
              <button
                type="button"
                key={index}
                className="bg-primary text-white text-xs rounded-lg px-2 py-2"
                onClick={() => setForm(Object.assign({}, form, item))}
              >
                <span>{item.location.value}</span>
              </button>
            );
          })}
        {recentSearches.length && (
          <button
            type="button"
            onClick={() => {
              let temp =
                Cookies.getCookie(
                  `recentSearches-${window.ddSearchForm.userId}`
                ) !== ""
                  ? JSON.parse(
                      Cookies.getCookie(
                        `recentSearches-${window.ddSearchForm.userId}`
                      )
                    )
                  : { flight: [], hotel: [], car: [], insurance: [] };
              temp.hotel = [];
              Cookies.setCookie(
                `recentSearches-${window.ddSearchForm.userId}`,
                JSON.stringify(temp)
              );
              setRecentSearches([]);
            }}
          >
            <i className="fa-solid fa-rotate-right text-primary"></i>
          </button>
        )}
      </div>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="flex flex-col gap-2 mt-2 lg:flex-row">
        {/* From */}
        <HotelSelector
          label="Destination, Hotel, Location"
          formKey="location"
          formValue={form.location}
          onSelectValue={onChangeValues}
          focusIndex={0}
          currentFocusIndex={currentFocusIndex}
          setCurrentFocusIndex={setCurrentFocusIndex}
        />

        {/* Dates */}
        <DateRangeInput
          startLabel="Check In Date"
          endLabel="Check Out Date"
          startKey="checkInDate"
          endKey="checkOutDate"
          startDate={form.checkInDate}
          endDate={form.checkOutDate}
          onSelectValue={onChangeValues}
          isRange={true}
          minDate={new Date()}
          focusIndex={1}
          currentFocusIndex={currentFocusIndex}
          setCurrentFocusIndex={setCurrentFocusIndex}
        />

        {/* Nights */}
        <NightSelector
          label="No. of Nights"
          formKey="nights"
          formValue={form.nights}
          onSelectValue={onChangeValues}
        />

        {/* Rooms & Guests */}
        <div className="static lg:relative grow">
          <div
            className={`bg-primary-100 px-4 pt-3 rounded cursor-pointer ${
              window.ddSearchForm.formType === "dashboard" ? "pb-7" : "pb-3"
            }`}
            onClick={() => setShowRoomsDropdown(!showRoomsDropdown)}
          >
            <span className="block text-sm text-[#888787] font-light">
              Rooms, Guests
            </span>
            <div className="flex">
              <span
                className={`block font-medium text-base ${
                  window.ddSearchForm.formType === "dashboard"
                    ? "lg:text-xl"
                    : "lg:text-base"
                }`}
              >
                {form.rooms.length}{" "}
                {form.rooms.length < 2 ? "Room, " : "Rooms, "}
                {calculateGuests()}
                {calculateGuests() < 2 ? " Guest" : " Guests"}
              </span>
            </div>
          </div>
          {/* Dropdown */}
          <div
            ref={roomsDropdownRef}
            className={`fixed top-0 left-0 p-3 w-screen h-screen bg-white rounded-lg border w-full z-[999] lg:shadow-[0_4px_11px_hsla(0,0%,0%,0.1)] lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-fit ${
              !showRoomsDropdown && "hidden"
            }`}
          >
            <div className="text-end">
              <button
                type="button"
                className="inline-block px-2 py-1 lg:hidden"
                onClick={() => setShowRoomsDropdown(!showRoomsDropdown)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            {/* Rooms */}
            <div>
              {form.rooms.map((room, i) => {
                return (
                  <RoomItem
                    key={i}
                    i={i}
                    room={room}
                    onToggle={() => handleRoomToggle(i)}
                    active={roomClicked === i}
                    form={form}
                    onChangeValues={onChangeValues}
                    countries={countriesList}
                  />
                );
              })}
              <button
                type="button"
                className="dd-button"
                onClick={() => {
                  let temp = form.rooms;
                  temp.push({
                    adults: 1,
                    children: [],
                    nationality: "Turkey",
                  });
                  onChangeValues("rooms", temp);
                  setRoomClicked(form.rooms?.length - 1);
                }}
              >
                <span>Add Room</span>
                <i className="fa-solid fa-plus ms-2"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Search Icon Button */}
        {window.ddSearchForm.formType !== "dashboard" && (
          <button className="dd-button" type="button" onClick={onSearchSubmit}>
            <i className="fa-solid fa-magnifying-glass text-xl"></i>
          </button>
        )}
      </div>
    );
  };

  return (
    !isLoading && (
      <div>
        {renderSearchForm()}
        {window.ddSearchForm.formType === "dashboard" && (
          <div className="text-center mt-6 mb-3">
            {" "}
            <button
              type="button"
              className="dd-button w-[180px]"
              onClick={onSearchSubmit}
            >
              Search
            </button>
          </div>
        )}
        {window.ddSearchForm.formType === "dashboard" && renderRecentSearches()}
      </div>
    )
  );
}
