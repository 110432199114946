import React, { useState } from "react";
import Select from "react-select";

export default function DropdownSelector({
  label,
  formKey,
  formValue,
  onSelectValue,
  options,
  wrapperClass,
}) {
  const [selectedOption, setSelectedOption] = useState(
    options?.find((item) => item.value === formValue)
  );
  const [showDropdown, setIsShowDropdown] = useState(false);
  const selectRef = React.useRef(null);

  const toggleShowDropdown = () => setIsShowDropdown(!showDropdown);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const onChangeValue = (option) => {
    setSelectedOption(option);
    onSelectValue(formKey, option.value);
    toggleShowDropdown();
  };

  return (
    <div className={`static lg:relative ${wrapperClass}`}>
      <div
        className={`bg-primary-100 px-4 pt-3 rounded flex justify-between gap-3 cursor-pointer pb-3`}
        onClick={toggleShowDropdown}
      >
        <div className={`w-full lg:w-fit mb-4`}>
          <span className="block text-sm text-[#888787] font-light">
            {label}
          </span>
          <div className="flex justify-between items-center gap-3">
            <span className={`block font-medium text-base lg:text-xl`}>
              {options?.find((item) => item.value === formValue)?.label ??
                `Select`}
            </span>
          </div>
        </div>
        <i
          className={`fa-solid fa-chevron-down text-sm my-auto ${
            showDropdown ? "rotate-180" : "rotate-0"
          }`}
        ></i>
      </div>
      {showDropdown && (
        <div
          ref={selectRef}
          className="fixed top-0 left-0 bg-white w-screen h-screen w-full z-[999] p-3 lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-full lg:p-0"
        >
          <div className="text-end">
            <button
              type="button"
              className="inline-block px-2 py-1 lg:hidden"
              onClick={toggleShowDropdown}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <Select
            value={selectedOption}
            onChange={onChangeValue}
            options={options ?? []}
            components={{
              Control: () => null,
            }}
            menuIsOpen={true}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                marginTop: 0,
              }),
            }}
            classNames={{
              option: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isSelected
                  ? "!bg-primary"
                  : state.isFocused
                  ? "!bg-primary-100"
                  : undefined,
            }}
          />
        </div>
      )}
    </div>
  );
}
