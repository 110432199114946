import React, { useState } from "react";
import FlightSelectionDropdown from "./FlightSelectionDropdown";

export default function FlightSelector({
  label,
  formKey,
  formValue,
  onSelectValue,
  isMulti,
  multiIndex,
  focusIndex,
  currentFocusIndex,
  setCurrentFocusIndex,
}) {
  const [showDropdown, setIsShowDropdown] = useState(false);
  const selectRef = React.useRef(null);

  const toggleShowDropdown = () => setIsShowDropdown(!showDropdown);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsShowDropdown(false);
        // Reset focus to arbitrary value on close
        setCurrentFocusIndex(3);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  React.useEffect(() => {
    if (currentFocusIndex === focusIndex) setIsShowDropdown(true);
  }, [currentFocusIndex]);

  return (
    <div className="static basis-3/12 lg:relative">
      <div
        className={`bg-primary-100 px-4 py-3 rounded relative flex flex-col justify-start basis-3/12 cursor-pointer h-full ${
          window.ddSearchForm.formType === "dashboard"
            ? "min-h-[84px]"
            : "min-h-[68px]"
        }`}
        onClick={toggleShowDropdown}
      >
        <span className="block text-sm text-[#888787] font-light">{label}</span>
        <span
          className={`block font-medium text-base truncate ${
            (formValue.city === "Flying From" ||
              formValue.city === "Flying To") &&
            "text-[#888787]"
          } ${
            window.ddSearchForm.formType === "dashboard"
              ? "lg:text-xl"
              : "lg:text-base"
          }`}
        >
          {formValue.city}
        </span>
        {window.ddSearchForm.formType === "dashboard" && (
          <p
            className={`text-xs truncate ${
              formValue.airport === "(City, Airport, Location)" &&
              "text-[#888787]"
            }`}
          >
            {formValue.airport}
          </p>
        )}
        <span
          className={`absolute top-3.5 right-5 text-lg ${
            window.ddSearchForm.formType === "dashboard"
              ? "lg:text-2xl"
              : "lg:text-lg"
          }`}
        >
          <i
            className={`fa-solid ${
              formKey === "from" ? "fa-plane-departure" : "fa-plane-arrival"
            }`}
          ></i>
        </span>
      </div>
      {showDropdown && (
        <FlightSelectionDropdown
          selectRef={selectRef}
          onSelectValue={onSelectValue}
          formKey={formKey}
          toggleShowDropdown={toggleShowDropdown}
          isMulti={isMulti}
          multiIndex={multiIndex}
          focusIndex={focusIndex}
          setCurrentFocusIndex={setCurrentFocusIndex}
        />
      )}
    </div>
  );
}
