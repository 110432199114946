export default class Cookies {
  static getCookie(name) {
    return (
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      ""
    );
  }

  static setCookie(name, value) {
    document.cookie = `${name}=${value}; expires=${new Date(
      new Date().setDate(new Date().getDate() + 400)
    ).toUTCString()}; path=/`;
  }
}
