import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

export default function LocationSelector({
  label,
  formKey,
  formValue,
  onSelectValue,
}) {
  const [showDropdown, setIsShowDropdown] = useState(false);
  const selectRef = React.useRef(null);

  const toggleShowDropdown = () => setIsShowDropdown(!showDropdown);

  const onChangeValue = async (opt) => {
    try {
      let geocode = await geocodeByAddress(opt.label);
      if (geocode) {
        let coordinates = await getLatLng(geocode[0]);
        if (coordinates) {
          let obj = {
            label: opt.label,
            name: opt.value?.terms[0]?.value,
            address: opt.label,
            types: opt.value?.types,
            lat: coordinates.lat,
            lng: coordinates.lng,
          };
          onSelectValue(formKey, obj);
        }
      }
    } catch (error) {
      console.log(error);
      let obj = {
        name: "",
        address: "",
        lat: "",
        lng: "",
        types: "",
      };
      onSelectValue(formKey, obj);
    }
    toggleShowDropdown();
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return (
    <div className="static basis-3/12 lg:relative">
      <div
        className={`bg-primary-100 px-4 py-3 rounded relative flex flex-col justify-start basis-3/12 cursor-pointer h-full ${
          window.ddSearchForm.formType === "dashboard"
            ? "min-h-[84px]"
            : "min-h-[68px]"
        }`}
        onClick={toggleShowDropdown}
      >
        <span className="block text-sm text-[#888787] font-light">{label}</span>
        <span
          className={`block font-medium text-base truncate ${
            formValue.name === "" && "text-[#888787]"
          } ${
            window.ddSearchForm.formType === "dashboard"
              ? "lg:text-xl"
              : "lg:text-base"
          }`}
        >
          {formValue.name === "" ? "Location" : formValue.name}
        </span>
        {window.ddSearchForm.formType === "dashboard" && (
          <p
            className={`text-xs truncate ${
              formValue.address === "" && "text-[#888787]"
            }`}
          >
            {formValue.address === "" ? "(City, Country)" : formValue.address}
          </p>
        )}
        <span
          className={`absolute top-3.5 right-5 text-lg ${
            window.ddSearchForm.formType === "dashboard"
              ? "lg:text-2xl"
              : "lg:text-lg"
          }`}
        >
          <i className={`fa-solid fa-map-pin`}></i>
        </span>
      </div>
      {showDropdown && (
        <div
          ref={selectRef}
          className="fixed top-0 left-0 bg-white w-screen h-screen z-[999] p-3 lg:rounded lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-full lg:p-0"
        >
          <div className="text-end">
            <button
              type="button"
              className="inline-block px-2 py-1 lg:hidden"
              onClick={toggleShowDropdown}
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
          <GooglePlacesAutocomplete
            apiKey={window.ddSearchForm.placesAPIKey}
            selectProps={{
              onChange: onChangeValue,
              components: {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              },
              menuIsOpen: true,
              placeholder: "Search",
              styles: {
                menu: (baseStyles) => ({
                  ...baseStyles,
                  marginTop: 0,
                }),
              },
              classNames: {
                control: (state) =>
                  state.isDisabled
                    ? undefined
                    : state.isFocused
                    ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                    : undefined,
                option: (state) =>
                  state.isDisabled
                    ? undefined
                    : state.isSelected
                    ? "!bg-primary"
                    : state.isFocused
                    ? "!bg-primary-100"
                    : undefined,
              },
              autoFocus: true,
            }}
          />
        </div>
      )}
    </div>
  );
}
