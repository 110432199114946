import React from "react";
import Flights from "./Flights";
import Hotels from "./Hotels";
import Cars from "./Cars";
import Insurance from "./Insurance";

const SearchForm = () => {
  const tabs = [
    {
      icon: "fa-solid fa-plane",
      label: "Flights",
    },
    {
      icon: "fa-solid fa-hotel",
      label: "Hotels",
    },
    {
      icon: "fa-solid fa-car",
      label: "Cars",
    },
    {
      icon: "fa-solid fa-shield-halved",
      label: "Insurance",
    },
    {
      icon: "fa-solid fa-globe",
      label: "Travel",
    },
    {
      icon: "fa-brands fa-cc-visa",
      label: "Visa",
    },
  ];

  const [activeTab, setActiveTab] = React.useState(0);

  const getTabClasses = (tabIndex) => {
    let classes =
      "flex items-center gap-2 border border-primary rounded-lg cursor-pointer px-3 py-2 ";
    // Add a space after px-4 py-3 to separate it from the additional classes

    if (tabIndex === activeTab) {
      classes += "bg-primary text-white";
    } else {
      classes += "text-black";
    }
    return classes;
  };

  const onSelectTab = (tabIndex) => setActiveTab(tabIndex);

  const renderSearchForm = () => {
    return (
      <div>
        <React.Fragment>
          {activeTab === 0 && <Flights />}
          {activeTab === 1 && <Hotels />}
          {activeTab === 2 && <Cars />}
          {activeTab === 3 && <Insurance />}
          {activeTab === 4 && <div>Coming soon...</div>}
          {activeTab === 5 && <div>Coming soon...</div>}
        </React.Fragment>
      </div>
    );
  };

  React.useEffect(() => {
    switch (window.ddSearchForm.formType) {
      case "hotels":
        setActiveTab(1);
        break;
      case "cars":
        setActiveTab(2);
        break;
      case "insurance":
        setActiveTab(3);
        break;
      case "travel":
        setActiveTab(4);
        break;
      case "visa":
        setActiveTab(5);
        break;
      default:
        setActiveTab(0);
        break;
    }
  }, []);

  return (
    <div
      className={`bg-white rounded-lg shadow p-4 ${
        window.ddSearchForm.formType === "dashboard" ? "lg:p-8" : "lg:p-3"
      }`}
    >
      {window.ddSearchForm.formType === "dashboard" && (
        <div className="flex gap-2 mb-3 w-full overflow-x-auto">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={getTabClasses(index)}
              onClick={() => onSelectTab(index)}
            >
              <i className={`${tab.icon} text-base font-medium`}></i>
              <span className={"text-base font-medium"}>{tab.label}</span>
            </div>
          ))}
        </div>
      )}
      {renderSearchForm()}
    </div>
  );
};

export default SearchForm;
