import React from "react";
import FlightSelector from "./FlightSelector";
import DateRangeInput from "../DateRangeInput";

export default function MultiFlightItem({
  item,
  i,
  onChangeValues,
  removeMultiFlight,
}) {
  const [currentFocusIndex, setCurrentFocusIndex] = React.useState();

  return (
    <div className="relative flex flex-col gap-2 mt-3 pt-7 border-t lg:flex-row lg:mt-2 lg:pt-0 lg:border-0">
      {/* From */}
      <FlightSelector
        label="From"
        formKey="from"
        formValue={item.from}
        onSelectValue={onChangeValues}
        isMulti={true}
        multiIndex={i}
        focusIndex={0}
        currentFocusIndex={currentFocusIndex}
        setCurrentFocusIndex={setCurrentFocusIndex}
      />

      {/* Swap */}
      <div
        className="flex items-center justify-end relative cursor-pointer -ms-2 -mt-2 lg:justify-start lg:mt-0"
        onClick={() =>
          onChangeValues(["from", "to"], [item.to, item.from], true, i)
        }
      >
        <div className="bg-primary-100 px-2 py-1 rounded-full absolute z-10 -ms-4 border-4 border-[#ffffff]">
          <i className="fa-solid fa-right-left text-primary rotate-90 lg:rotate-0"></i>
        </div>
      </div>

      {/* To */}
      <FlightSelector
        label="To"
        formKey="to"
        formValue={item.to}
        onSelectValue={onChangeValues}
        isMulti={true}
        multiIndex={i}
        focusIndex={1}
        currentFocusIndex={currentFocusIndex}
        setCurrentFocusIndex={setCurrentFocusIndex}
      />

      {/* Dates */}
      <DateRangeInput
        startLabel="Departure Date"
        startKey="fromDate"
        startDate={item.fromDate}
        onSelectValue={onChangeValues}
        isRange={false}
        isMulti={true}
        multiIndex={i}
        minDate={new Date()}
        wrapperClass="basis-3/12"
        focusIndex={2}
        currentFocusIndex={currentFocusIndex}
        setCurrentFocusIndex={setCurrentFocusIndex}
      />
      <div className="absolute top-0 right-0 flex items-center justify-end lg:static lg:justify-start lg:basis-3/12">
        <i
          className="fa-solid fa-xmark cursor-pointer text-xl text-[#ff0000]"
          onClick={() => removeMultiFlight(i)}
        ></i>
      </div>
    </div>
  );
}
