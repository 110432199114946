import React, { forwardRef } from "react";

const DateRangeSelector = forwardRef(
  ({ value, onClick, startLabel, endLabel, isRange }, ref) => {
    const startDate = new Date(value.split("-")[0]);
    const endDate = new Date(value.split("-")[1]);

    return (
      <div
        ref={ref}
        onClick={onClick}
        className="bg-primary-100 px-4 py-3 rounded flex justify-between gap-3 cursor-pointer w-full"
      >
        <div className="w-full lg:w-32">
          <span className="block text-sm text-[#888787] font-light">
            {startLabel}
          </span>
          {startDate && !isNaN(startDate) ? (
            <span
              className={`block font-medium text-base lg:truncate ${
                window.ddSearchForm.formType === "dashboard"
                  ? "lg:text-xl"
                  : "lg:text-base"
              }`}
            >
              {startDate.getDate()}{" "}
              {startDate.toLocaleString("default", { month: "short" })}{" "}
              {startDate.getFullYear()}
            </span>
          ) : (
            <span
              className={`block font-medium text-base ${
                window.ddSearchForm.formType === "dashboard"
                  ? "lg:text-xl"
                  : "lg:text-base"
              }`}
            >
              dd mm yyyy
            </span>
          )}
          {window.ddSearchForm.formType === "dashboard" && (
            <>
              {startDate && !isNaN(startDate) ? (
                <p className="text-xs">
                  {startDate.toLocaleString("default", { weekday: "long" })}
                </p>
              ) : (
                <p className="text-xs">day</p>
              )}
            </>
          )}
        </div>
        {isRange && (
          <>
            <div className=" bg-[#C5C5C5] w-px h-full"></div>
            <div className="w-full lg:w-32">
              <span className="block text-sm text-[#888787] font-light">
                {endLabel}
              </span>
              {endDate && !isNaN(endDate) ? (
                <span
                  className={`block font-medium text-base lg:truncate ${
                    window.ddSearchForm.formType === "dashboard"
                      ? "lg:text-xl"
                      : "lg:text-base"
                  }`}
                >
                  {endDate.getDate()}{" "}
                  {endDate.toLocaleString("default", { month: "short" })}{" "}
                  {endDate.getFullYear()}
                </span>
              ) : (
                <span
                  className={`block font-medium text-base ${
                    window.ddSearchForm.formType === "dashboard"
                      ? "lg:text-xl"
                      : "lg:text-base"
                  }`}
                >
                  dd mm yyyy
                </span>
              )}
              {window.ddSearchForm.formType === "dashboard" && (
                <>
                  {endDate && !isNaN(endDate) ? (
                    <p className="text-xs">
                      {endDate.toLocaleString("default", { weekday: "long" })}
                    </p>
                  ) : (
                    <p className="text-xs">day</p>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);

export default DateRangeSelector;
