import React from "react";
import FlightSelector from "./FlightSelector";
import DateRangeInput from "../DateRangeInput";
import unixTimeToDate from "../../util/convertUnixToDate";
import getDateOnly from "../../util/getDateOnly";
import SearchItemPart from "./SearchItemPart";
import Cookies from "../../util/cookies";
import MultiFlightItem from "./MultiFlightItem";

export default function Flights() {
  const [form, setForm] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const [showPassengerDropdown, setShowPassengerDropdown] =
    React.useState(false);
  const passengerDropdownRef = React.useRef(null);
  const [showMultiFlights, setShowMultiFlights] = React.useState(true);

  const [recentSearches, setRecentSearches] = React.useState([]);

  const [currentFocusIndex, setCurrentFocusIndex] = React.useState();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("data");
    if (myParam) {
      setForm(JSON.parse(myParam));
    } else {
      setForm({
        from: {
          id: "",
          city: "Flying From",
          airport: "(City, Airport, Location)",
          airport_code: "",
        },
        to: {
          id: "",
          city: "Flying To",
          airport: "(City, Airport, Location)",
          airport_code: "",
        },
        fromDate: new Date(),
        toDate: unixTimeToDate(new Date().setDate(new Date().getDate() + 1)),
        multiFlights: [],
        tripType: "one-way",
        adults: 1,
        children: 0,
        infants: 0,
        class: "economy",
        nonstop: false,
      });
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        passengerDropdownRef.current &&
        !passengerDropdownRef.current.contains(event.target)
      ) {
        setShowPassengerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [passengerDropdownRef]);

  React.useEffect(() => {
    if (
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
    ) {
      setRecentSearches(
        JSON.parse(
          Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
        )?.flight
      );
    }
  }, []);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (typeof form.fromDate === "string") {
      form.fromDate = getDateOnly(new Date(Date.parse(form.fromDate)));
    } else {
      form.fromDate = getDateOnly(form.fromDate);
    }
    if (typeof form.toDate === "string") {
      form.toDate = getDateOnly(new Date(Date.parse(form.toDate)));
    } else {
      form.toDate = getDateOnly(form.toDate);
    }
    if (form.tripType === "multi-city" && form.multiFlights.length > 0) {
      form.multiFlights.map((item) => {
        if (typeof item.fromDate === "string") {
          item.fromDate = getDateOnly(new Date(Date.parse(item.fromDate)));
        } else {
          item.fromDate = getDateOnly(item.fromDate);
        }
        return item;
      });
    }
    window.location =
      window.ddSearchForm.flightActionURL + "?data=" + JSON.stringify(form);

    // save search data in cookies
    let temp =
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
        ? JSON.parse(
            Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
          )
        : { flight: [], hotel: [], car: [], insurance: [] };
    // check if the same object does not exist already
    if (
      temp.flight &&
      !temp.flight.some((obj) => JSON.stringify(obj) === JSON.stringify(form))
    ) {
      if (temp.flight && temp.flight.length > 2) {
        temp.flight.splice(0, 1);
      }
      temp.flight.push(form);
      Cookies.setCookie(
        `recentSearches-${window.ddSearchForm.userId}`,
        JSON.stringify(temp)
      );
    }
  };

  const onChangeTripType = (e) => {
    if (e.target.checked) {
      let temp = form.fromDate;
      if (typeof temp === "string") {
        temp = new Date(Date.parse(temp));
      }
      setForm(
        Object.assign({}, form, {
          tripType: e.target.value,
          toDate: unixTimeToDate(new Date().setDate(temp.getDate() + 1)),
        })
      );
    }
  };

  const onChangeValues = (key, value, isMulti, index) => {
    if (isMulti) {
      // For multi-city
      if (key instanceof Array && value instanceof Array) {
        // if update more than one properties in multiFlights
        let flights = form.multiFlights;
        for (let i = 0; i < key.length; i++) {
          flights[index][key[i]] = Object.assign({}, flights[index], {
            [key]: value,
          });
        }
        setForm(Object.assign({}, form, { multiFlights: flights }));
      } else {
        // if update just single property in multiFlights
        let flights = form.multiFlights;
        flights[index] = Object.assign({}, flights[index], { [key]: value });
        setForm(Object.assign({}, form, { multiFlights: flights }));
      }
    } else {
      // For round-trip or one-way
      if (key instanceof Array && value instanceof Array) {
        // if update more than one properties in form
        let temp = {};
        for (let i = 0; i < key.length; i++) {
          temp[key[i]] = value[i];
        }
        setForm(Object.assign({}, form, temp));
      } else {
        // if update just single property in form
        setForm(Object.assign({}, form, { [key]: value }));
      }
    }
  };

  const addFlight = () => {
    let temp = form.multiFlights;
    // if already has multi flights
    if (temp.length > 0) {
      temp.push({
        from:
          temp[temp.length - 1]?.to.city !== "Flying To"
            ? temp[temp.length - 1]?.to
            : {
                id: "",
                city: "Flying From",
                airport: "(City, Airport, Location)",
                airport_code: "",
              },
        to: {
          id: "",
          city: "Flying To",
          airport: "(City, Airport, Location)",
          airport_code: "",
        },
        fromDate: form.multiFlights[form.multiFlights?.length - 1]?.fromDate,
      });
    } else {
      // if no multi flight added yet
      temp.push({
        from: form.to,
        to: {
          id: "",
          city: "Flying To",
          airport: "(City, Airport, Location)",
          airport_code: "",
        },
        fromDate: form.fromDate,
      });
    }
    setForm(Object.assign({}, form, { multiFlights: temp }));
  };

  const removeMultiFlight = (index) => {
    let temp = form.multiFlights;
    temp.splice(index, 1);
    setForm(Object.assign({}, form, { multiFlights: temp }));
  };

  React.useEffect(() => {
    if (window.ddSearchForm.formType === "dashboard") {
      setShowMultiFlights(true);
    } else {
      setShowMultiFlights(false);
    }
  }, []);

  const renderTripTypesRadioButtons = () => {
    return (
      <div className="flex flex-wrap justify-start md:justify-end gap-2">
        <label className="cursor-pointer text-base font-medium">
          <input
            type="radio"
            name="tripType"
            value="one-way"
            checked={form.tripType === "one-way"}
            onChange={onChangeTripType}
            className="accent-primary"
          />{" "}
          One Way
        </label>
        <label className="cursor-pointer text-base font-medium">
          <input
            type="radio"
            name="tripType"
            value="round-trip"
            checked={form.tripType === "round-trip"}
            onChange={onChangeTripType}
            className="accent-primary"
          />{" "}
          Round Trip
        </label>
        <label className="cursor-pointer text-base font-medium">
          <input
            type="radio"
            name="tripType"
            value="multi-city"
            checked={form.tripType === "multi-city"}
            onChange={onChangeTripType}
            className="accent-primary"
          />{" "}
          Multi City
        </label>
      </div>
    );
  };

  const renderRecentSearches = () => {
    if (recentSearches.length < 1) {
      return null;
    }

    return (
      <div className="flex flex-wrap gap-2 items-center">
        {" "}
        {/* Use div instead of React.Fragment */}
        <span className="font-medium">Recent Searches</span>
        {recentSearches.length &&
          recentSearches.map((item, index) => {
            if (item.tripType !== "multi-city") {
              return (
                <button
                  type="button"
                  key={index}
                  className="bg-primary text-white text-xs rounded-lg px-2 py-2"
                  onClick={() => setForm(Object.assign({}, form, item))}
                >
                  <span>{item.from.value}</span>
                  <i className="fa-solid fa-arrow-right-long mx-2"></i>
                  <span>{item.to.value}</span>
                </button>
              );
            } else {
              return (
                <button
                  type="button"
                  key={index}
                  className="bg-primary text-white text-xs rounded-lg px-2 py-2 flex gap-1 items-center w-fit overflow-x-auto"
                  onClick={() => setForm(Object.assign({}, form, item))}
                >
                  <div className="flex items-center">
                    <span>{item.from.value}</span>
                    <i className="fa-solid fa-arrow-right-long mx-2"></i>
                    <span>{item.to.value}</span>
                  </div>
                  {item.multiFlights.length && (
                    <SearchItemPart items={item.multiFlights} />
                  )}
                </button>
              );
            }
          })}
        {recentSearches.length && (
          <button
            type="button"
            onClick={() => {
              let temp =
                Cookies.getCookie(
                  `recentSearches-${window.ddSearchForm.userId}`
                ) !== ""
                  ? JSON.parse(
                      Cookies.getCookie(
                        `recentSearches-${window.ddSearchForm.userId}`
                      )
                    )
                  : { flight: [], hotel: [], car: [], insurance: [] };
              temp.flight = [];
              Cookies.setCookie(
                `recentSearches-${window.ddSearchForm.userId}`,
                JSON.stringify(temp)
              );
              setRecentSearches([]);
            }}
          >
            <i className="fa-solid fa-rotate-right text-primary"></i>
          </button>
        )}
      </div>
    );
  };

  const renderMultiFlights = () => {
    return (
      <div className={showMultiFlights ? "block" : "hidden"}>
        {window.ddSearchForm.formType !== "dashboard" && (
          <div className="flex flex-col gap-2 mt-3 pt-3 border-t lg:flex-row lg:mt-2 lg:pt-0 lg:border-0">
            {/* From */}
            <FlightSelector
              label="From"
              formKey="from"
              formValue={form.from}
              onSelectValue={onChangeValues}
              focusIndex={0}
              currentFocusIndex={currentFocusIndex}
              setCurrentFocusIndex={setCurrentFocusIndex}
            />

            {/* Swap */}
            <div
              className="flex items-center justify-end relative cursor-pointer -ms-2 -mt-2 lg:justify-start lg:mt-0"
              onClick={() =>
                onChangeValues(["from", "to"], [form.to, form.from])
              }
            >
              <div className="bg-primary-100 px-2 py-1 rounded-full absolute z-10 -ms-4 border-4 border-[#ffffff]">
                <i className="fa-solid fa-right-left text-primary rotate-90 lg:rotate-0"></i>
              </div>
            </div>

            {/* To */}
            <FlightSelector
              label="To"
              formKey="to"
              formValue={form.to}
              onSelectValue={onChangeValues}
              focusIndex={1}
              currentFocusIndex={currentFocusIndex}
              setCurrentFocusIndex={setCurrentFocusIndex}
            />

            {/* Dates */}
            <DateRangeInput
              startLabel="Departure Date"
              endLabel="Return Date"
              startKey="fromDate"
              endKey="toDate"
              startDate={form.fromDate}
              endDate={form.toDate}
              onSelectValue={onChangeValues}
              isRange={form.tripType === "round-trip"}
              minDate={new Date()}
              wrapperClass="basis-3/12"
              focusIndex={2}
              currentFocusIndex={currentFocusIndex}
              setCurrentFocusIndex={setCurrentFocusIndex}
            />
          </div>
        )}
        {form.multiFlights
          ? form.multiFlights.map((item, i) => {
              return (
                <MultiFlightItem
                  key={i}
                  item={item}
                  i={i}
                  onChangeValues={onChangeValues}
                  removeMultiFlight={removeMultiFlight}
                />
              );
            })
          : null}
        <button className="dd-button mt-2" type="button" onClick={addFlight}>
          Add Flight
        </button>
      </div>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="flex flex-col gap-2 mt-2 lg:flex-row">
        {window.ddSearchForm.formType !== "dashboard" &&
        form.tripType === "multi-city" ? (
          <div className="relative w-full lg:w-2/3">
            <div
              className={`bg-primary-100 px-4 py-3 rounded relative flex flex-row justify-between items-start basis-3/12 cursor-pointer ${
                window.ddSearchForm.formType === "dashboard"
                  ? "min-h-[84px]"
                  : "min-h-[68px]"
              }`}
              onClick={() => setShowMultiFlights(!showMultiFlights)}
            >
              <div>
                <span className="block text-sm text-[#888787] font-light">
                  From
                </span>
                <span
                  className={`block font-medium ${
                    form.multiFlights.length < 1 && "text-[#888787]"
                  } ${
                    window.ddSearchForm.formType === "dashboard"
                      ? "text-xl"
                      : "text-base"
                  }`}
                >
                  {form.multiFlights.length < 1
                    ? "No Flights Added"
                    : `${
                        form.from?.id !== "" ? form.from?.city : "Source"
                      } to ${
                        form.multiFlights[form.multiFlights.length - 1]?.to
                          ?.id !== ""
                          ? form.multiFlights[form.multiFlights.length - 1]?.to
                              ?.city
                          : "Destination"
                      }`}
                </span>
              </div>
              {showMultiFlights ? (
                <i className="fa-solid fa-chevron-up my-auto"></i>
              ) : (
                <i className="fa-solid fa-chevron-down my-auto"></i>
              )}
            </div>
          </div>
        ) : (
          <>
            {/* From */}
            <FlightSelector
              label="From"
              formKey="from"
              formValue={form.from}
              onSelectValue={onChangeValues}
              focusIndex={0}
              currentFocusIndex={currentFocusIndex}
              setCurrentFocusIndex={setCurrentFocusIndex}
            />

            {/* Swap */}
            <div
              className="flex items-center justify-end relative cursor-pointer -ms-2 -mt-2 lg:justify-start lg:mt-0"
              onClick={() =>
                onChangeValues(["from", "to"], [form.to, form.from])
              }
            >
              <div className="bg-primary-100 px-2 py-1 rounded-full absolute z-10 -ms-4 border-4 border-[#ffffff]">
                <i className="fa-solid fa-right-left text-primary rotate-90 lg:rotate-0"></i>
              </div>
            </div>

            {/* To */}
            <FlightSelector
              label="To"
              formKey="to"
              formValue={form.to}
              onSelectValue={onChangeValues}
              focusIndex={1}
              currentFocusIndex={currentFocusIndex}
              setCurrentFocusIndex={setCurrentFocusIndex}
            />

            {/* Dates */}
            <DateRangeInput
              startLabel="Departure Date"
              endLabel="Return Date"
              startKey="fromDate"
              endKey="toDate"
              startDate={form.fromDate}
              endDate={form.toDate}
              onSelectValue={onChangeValues}
              isRange={form.tripType === "round-trip"}
              minDate={new Date()}
              wrapperClass="basis-3/12"
              focusIndex={2}
              currentFocusIndex={currentFocusIndex}
              setCurrentFocusIndex={setCurrentFocusIndex}
            />
          </>
        )}

        {/* Passengers */}
        <div className="static lg:relative basis-3/12">
          <div
            className="bg-primary-100 px-4 py-3 rounded cursor-pointer"
            onClick={() => setShowPassengerDropdown(!showPassengerDropdown)}
          >
            <span className="block text-sm text-[#888787] font-light">
              Passenger, Class
            </span>
            <div className="flex">
              <span
                className={`block font-medium text-base ${
                  window.ddSearchForm.formType === "dashboard"
                    ? "lg:text-xl"
                    : "lg:text-base"
                }`}
              >
                {form.adults + form.children + form.infants}{" "}
                {form.adults + form.children + form.infants < 2
                  ? "Passenger"
                  : "Passengers"}
              </span>
              {window.ddSearchForm.formType !== "dashboard" && (
                <p
                  className={`font-medium capitalize text-base ${
                    window.ddSearchForm.formType === "dashboard"
                      ? "lg:text-xl"
                      : "lg:text-base"
                  }`}
                >
                  , {form.class}
                </p>
              )}
            </div>
            {window.ddSearchForm.formType === "dashboard" && (
              <p className="block text-xs capitalize">
                {form.class}
                {form.nonstop && ", Non-stop"}
              </p>
            )}
          </div>
          {/* Dropdown */}
          <div
            ref={passengerDropdownRef}
            className={`fixed top-0 left-0 p-3 w-screen h-screen bg-white rounded-lg border w-full z-[999] lg:shadow-[0_4px_11px_hsla(0,0%,0%,0.1)] lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-fit ${
              !showPassengerDropdown && "hidden"
            }`}
          >
            <div className="text-end">
              <button
                type="button"
                className="inline-block px-2 py-1 lg:hidden"
                onClick={() => setShowPassengerDropdown(!showPassengerDropdown)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            {/* Travelers */}
            <div className="mb-3">
              <p className="font-medium border-b pb-2 mb-2">Travelers</p>
              <div className="flex justify-between mb-2">
                <p>Adults</p>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className={`px-2 rounded ${
                      form.adults < 2 ? "bg-gray-100" : "bg-primary-100"
                    }`}
                    disabled={form.adults < 2}
                    onClick={() => onChangeValues("adults", form.adults - 1)}
                  >
                    -
                  </button>
                  <p className="w-6 text-center">{form.adults}</p>
                  <button
                    type="button"
                    className="bg-primary-100 px-2 rounded"
                    onClick={() => onChangeValues("adults", form.adults + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <p>Children</p>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className={`px-2 rounded ${
                      form.children < 1 ? "bg-gray-100" : "bg-primary-100"
                    }`}
                    disabled={form.children < 1}
                    onClick={() =>
                      onChangeValues("children", form.children - 1)
                    }
                  >
                    -
                  </button>
                  <p className="w-6 text-center">{form.children}</p>
                  <button
                    type="button"
                    className="bg-primary-100 px-2 rounded"
                    onClick={() =>
                      onChangeValues("children", form.children + 1)
                    }
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex justify-between">
                <p>Infants</p>
                <div className="flex gap-2">
                  <button
                    type="button"
                    className={`px-2 rounded ${
                      form.infants < 1 ? "bg-gray-100" : "bg-primary-100"
                    }`}
                    disabled={form.infants < 1}
                    onClick={() => onChangeValues("infants", form.infants - 1)}
                  >
                    -
                  </button>
                  <p className="w-6 text-center">{form.infants}</p>
                  <button
                    type="button"
                    className="bg-primary-100 px-2 rounded"
                    onClick={() => onChangeValues("infants", form.infants + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            {/* Class */}
            <div className="mb-3">
              <p className="font-medium border-b pb-2 mb-2">Class</p>
              <div className="flex flex-wrap gap-2">
                <button
                  type="button"
                  className={`py-1 px-3 rounded ${
                    form.class === "economy"
                      ? "bg-primary text-white"
                      : "bg-gray-100"
                  }`}
                  onClick={() => onChangeValues("class", "economy")}
                >
                  Economy
                </button>
                <button
                  type="button"
                  className={`py-1 px-3 rounded ${
                    form.class === "premium"
                      ? "bg-primary text-white"
                      : "bg-gray-100"
                  }`}
                  onClick={() => onChangeValues("class", "premium")}
                >
                  Premium
                </button>
                <button
                  type="button"
                  className={`py-1 px-3 rounded ${
                    form.class === "first"
                      ? "bg-primary text-white"
                      : "bg-gray-100"
                  }`}
                  onClick={() => onChangeValues("class", "first")}
                >
                  First
                </button>
                <button
                  type="button"
                  className={`py-1 px-3 rounded ${
                    form.class === "business"
                      ? "bg-primary text-white"
                      : "bg-gray-100"
                  }`}
                  onClick={() => onChangeValues("class", "business")}
                >
                  Business
                </button>
              </div>
            </div>
            {/* Non-stop Flight */}
            <div>
              <p className="font-medium border-b pb-2 mb-2">Non-stop Flight</p>
              <div className="flex flex-wrap gap-2">
                <button
                  type="button"
                  className={`py-1 px-3 rounded ${
                    form.nonstop ? "bg-primary text-white" : "bg-gray-100"
                  }`}
                  onClick={() => onChangeValues("nonstop", true)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`py-1 px-3 rounded ${
                    !form.nonstop ? "bg-primary text-white" : "bg-gray-100"
                  }`}
                  onClick={() => onChangeValues("nonstop", false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Search Icon Button */}
        {window.ddSearchForm.formType !== "dashboard" && (
          <button className="dd-button" type="button" onClick={onSearchSubmit}>
            <i className="fa-solid fa-magnifying-glass text-xl"></i>
          </button>
        )}
      </div>
    );
  };

  return (
    !isLoading && (
      <div>
        <div
          className={`flex flex-col lg:flex-row ${
            window.ddSearchForm.formType === "flights"
              ? "justify-between"
              : "justify-start"
          }`}
        >
          {renderTripTypesRadioButtons()}
        </div>
        {renderSearchForm()}
        {form.tripType === "multi-city" && renderMultiFlights()}
        {window.ddSearchForm.formType === "dashboard" && (
          <div className="text-center mt-6 mb-3">
            {" "}
            <button
              type="button"
              className="dd-button w-[180px]"
              onClick={onSearchSubmit}
            >
              Search
            </button>
          </div>
        )}
        {window.ddSearchForm.formType === "dashboard" && renderRecentSearches()}
      </div>
    )
  );
}
