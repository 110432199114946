import React, { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import DateRangeSelector from "./DateRangeSelector";
import "react-datepicker/dist/react-datepicker.css";

export default function DateRangeInput({
  startLabel,
  endLabel,
  startKey,
  endKey,
  startDate,
  endDate,
  onSelectValue,
  isRange,
  isMulti,
  multiIndex,
  wrapperClass,
  minDate,
  focusIndex,
  currentFocusIndex,
  setCurrentFocusIndex,
}) {
  const pickerRef = useRef(null);

  React.useEffect(() => {
    if (currentFocusIndex && focusIndex === currentFocusIndex) {
      pickerRef.current.setOpen(true);
    }
  }, [currentFocusIndex]);

  return (
    <div className={`${wrapperClass}`}>
      {isRange ? (
        <ReactDatePicker
          ref={pickerRef}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(value) => {
            if (isMulti) {
              onSelectValue([startKey, endKey], value, true, multiIndex);
            } else {
              onSelectValue([startKey, endKey], value);
            }
            // Reset Focus to arbitrary value
            if (currentFocusIndex) {
              setCurrentFocusIndex(3);
            }
          }}
          onCalendarClose={() => currentFocusIndex && setCurrentFocusIndex(3)}
          customInput={
            <DateRangeSelector
              startLabel={startLabel}
              endLabel={endLabel}
              startDate={startDate}
              endDate={endDate}
              isRange={isRange}
              focusIndex={focusIndex}
              currentFocusIndex={currentFocusIndex}
            />
          }
          minDate={minDate}
          monthsShown={2}
          selectsRange={isRange}
        />
      ) : (
        <ReactDatePicker
          ref={pickerRef}
          selected={startDate}
          startDate={startDate}
          onChange={(value) => {
            if (isMulti) {
              onSelectValue(startKey, value, true, multiIndex);
            } else {
              onSelectValue(startKey, value);
            }
            // Reset Focus to arbitrary value
            if (currentFocusIndex) {
              setCurrentFocusIndex(3);
            }
          }}
          onCalendarClose={() => currentFocusIndex && setCurrentFocusIndex(3)}
          customInput={
            <DateRangeSelector
              startLabel={startLabel}
              startDate={startDate}
              isRange={isRange}
              focusIndex={focusIndex}
              currentFocusIndex={currentFocusIndex}
            />
          }
          minDate={minDate}
          monthsShown={2}
          selectsRange={isRange}
        />
      )}
    </div>
  );
}
