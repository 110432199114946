import React from "react";
import Select from "react-select";

export default function LocationsSelector({ form, onChangeValues, options }) {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const dropdownRef = React.useRef(null);
  const [selectedOrigin, setSelectedOrigin] = React.useState(
    options?.find((item) => item.value === form.origin)
  );
  const [selectedDestination, setSelectedDestination] = React.useState(
    options?.find((item) => item.value === form.destination)
  );

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="static lg:relative grow">
      <div
        className="bg-primary-100 px-4 py-3 rounded cursor-pointer min-h-[86px]"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <span className="block text-sm text-[#888787] font-light">
          Origin & Destination
        </span>
        <div className="flex">
          <span className={`block font-medium text-base lg:text-xl truncate`}>
            {form.origin && form.origin !== "" ? form.origin : "Origin"}
          </span>
        </div>
        <p className="block text-xs capitalize truncate">
          {form.destination && form.destination !== ""
            ? form.destination
            : "Destination"}
        </p>
      </div>
      {/* Dropdown */}
      <div
        ref={dropdownRef}
        className={`fixed top-0 left-0 p-3 w-screen h-screen bg-white rounded-lg border w-full z-[999] lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-fit ${
          !showDropdown && "hidden"
        }`}
      >
        <div className="text-end">
          <button
            type="button"
            className="inline-block px-2 py-1 lg:hidden"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        {/* Travelers */}
        <div className="mb-3">
          <p className="mb-2">Origin</p>
          <Select
            value={selectedOrigin}
            onChange={(opt) => {
              setSelectedOrigin(opt);
              onChangeValues("origin", opt.value);
            }}
            options={options}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                marginTop: 0,
              }),
            }}
            classNames={{
              control: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isFocused
                  ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                  : undefined,
              option: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isSelected
                  ? "!bg-primary"
                  : state.isFocused
                  ? "!bg-primary-100"
                  : undefined,
            }}
          />
          <p className="mt-4 mb-2">Destination</p>
          <Select
            value={selectedDestination}
            onChange={(opt) => {
              setSelectedDestination(opt);
              onChangeValues("destination", opt.value);
            }}
            options={options}
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                marginTop: 0,
              }),
            }}
            classNames={{
              control: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isFocused
                  ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                  : undefined,
              option: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isSelected
                  ? "!bg-primary"
                  : state.isFocused
                  ? "!bg-primary-100"
                  : undefined,
            }}
          />
        </div>
      </div>
    </div>
  );
}
