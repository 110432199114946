import React from "react";
import Select from "react-select";

export default function RoomItem({
  i,
  room,
  onToggle,
  active,
  form,
  onChangeValues,
  countries,
}) {
  const adultOpts = [1, 2, 3, 4, 5, 6, 7, 8].map((item) => ({
    label: item,
    value: item,
  }));
  const childOpts = [0, 1, 2, 3, 4].map((item) => ({
    label: item,
    value: item,
  }));
  const countryOpts = countries.map((item) => ({ label: item, value: item }));
  const ageOpts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({
    label: item,
    value: item,
  }));
  return (
    <div className="mb-3">
      <div
        className="flex justify-between font-medium p-3 rounded bg-primary-100 cursor-pointer"
        onClick={onToggle}
      >
        <div>
          <i
            className={`fa-solid fa-chevron-down text-sm me-2 ${
              active ? "rotate-180" : "rotate-0"
            }`}
          ></i>
          <span>Room {i + 1}</span>
        </div>
        {i > 0 && (
          <button
            type="button"
            onClick={() => {
              let temp = form.rooms;
              temp.splice(i, 1);
              onChangeValues("rooms", temp);
            }}
          >
            <i className="fa-solid fa-trash text-[rgb(255,0,0)]"></i>
          </button>
        )}
      </div>
      <div className={`grid-cols-3 gap-3 p-2 ${active ? "grid" : "hidden"}`}>
        <div>
          <label className="font-medium text-sm">Adult(s)</label>
          <Select
            value={adultOpts.find((opt) => opt.value === room.adults)}
            options={adultOpts}
            onChange={(opt) => {
              let temp = form.rooms;
              temp[i].adults = opt.value;
              onChangeValues("rooms", temp);
            }}
            components={{ IndicatorSeparator: null }}
            styles={{
              control: (styles) => ({
                ...styles,
                fontSize: "14px",
                borderRadius: "8px",
              }),
              option: (styles) => ({
                ...styles,
                fontSize: "14px",
                paddingTop: "3px",
                paddingBottom: "3px",
              }),
            }}
            classNames={{
              control: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isFocused
                  ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                  : undefined,
              option: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isSelected
                  ? "!bg-primary"
                  : state.isFocused
                  ? "!bg-primary-100"
                  : undefined,
            }}
          />
        </div>
        <div>
          <label className="font-medium text-sm">Child(ren)</label>
          <Select
            value={childOpts.find((opt) => opt.value === room.children.length)}
            options={childOpts}
            onChange={(opt) => {
              let temp = form.rooms;
              temp[i].children =
                opt.value > 0
                  ? Array.from(new Array(opt.value)).map(() => ({
                      age: 0,
                    }))
                  : [];
              onChangeValues("rooms", temp);
            }}
            components={{ IndicatorSeparator: null }}
            styles={{
              control: (styles) => ({
                ...styles,
                fontSize: "14px",
                borderRadius: "8px",
              }),
              option: (styles) => ({
                ...styles,
                fontSize: "14px",
                paddingTop: "3px",
                paddingBottom: "3px",
              }),
            }}
            classNames={{
              control: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isFocused
                  ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                  : undefined,
              option: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isSelected
                  ? "!bg-primary"
                  : state.isFocused
                  ? "!bg-primary-100"
                  : undefined,
            }}
          />
        </div>
        <div>
          <label className="font-medium text-sm">Nationality</label>
          <Select
            value={countryOpts.find((opt) => opt.value === room.nationality)}
            options={countryOpts}
            onChange={(opt) => {
              let temp = form.rooms;
              temp[i].nationality = opt.value;
              onChangeValues("rooms", temp);
            }}
            components={{ IndicatorSeparator: null }}
            styles={{
              control: (styles) => ({
                ...styles,
                fontSize: "14px",
                borderRadius: "8px",
              }),
              option: (styles) => ({
                ...styles,
                fontSize: "14px",
                paddingTop: "3px",
                paddingBottom: "3px",
              }),
            }}
            classNames={{
              control: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isFocused
                  ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                  : undefined,
              option: (state) =>
                state.isDisabled
                  ? undefined
                  : state.isSelected
                  ? "!bg-primary"
                  : state.isFocused
                  ? "!bg-primary-100"
                  : undefined,
            }}
          />
        </div>
        {room.children.length > 0 &&
          room.children.map((item, i1) => {
            return (
              <div key={i1}>
                <label className="font-medium text-sm">Child {i1 + 1}</label>
                <Select
                  defaultValue={ageOpts[0]}
                  value={ageOpts.find((opt) => opt.value === item.age)}
                  options={ageOpts}
                  onChange={(opt) => {
                    let temp = form.rooms;
                    temp[i].children[i1].age = opt.value;
                    onChangeValues("rooms", temp);
                  }}
                  components={{ IndicatorSeparator: null }}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      fontSize: "14px",
                      borderRadius: "8px",
                    }),
                    option: (styles) => ({
                      ...styles,
                      fontSize: "14px",
                      paddingTop: "3px",
                      paddingBottom: "3px",
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      state.isDisabled
                        ? undefined
                        : state.isFocused
                        ? "!border-primary !shadow-[0_0_0_1px_rgba(0,0,0,0.3)] !shadow-primary"
                        : undefined,
                    option: (state) =>
                      state.isDisabled
                        ? undefined
                        : state.isSelected
                        ? "!bg-primary"
                        : state.isFocused
                        ? "!bg-primary-100"
                        : undefined,
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
