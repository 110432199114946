import React from "react";

export default function PassengerSelector({ form, onChangeValues }) {
  const [showPassengerDropdown, setShowPassengerDropdown] =
    React.useState(false);
  const passengerDropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        passengerDropdownRef.current &&
        !passengerDropdownRef.current.contains(event.target)
      ) {
        setShowPassengerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [passengerDropdownRef]);

  return (
    <div className="static lg:relative grow">
      <div
        className="bg-primary-100 px-4 py-3 rounded cursor-pointer"
        onClick={() => setShowPassengerDropdown(!showPassengerDropdown)}
      >
        <span className="block text-sm text-[#888787] font-light">
          Passengers & Luggage
        </span>
        <div className="flex">
          <span
            className={`block font-medium text-base ${
              window.ddSearchForm.formType === "dashboard"
                ? "lg:text-xl"
                : "lg:text-base"
            }`}
          >
            {form.adults + form.children}{" "}
            {form.adults + form.children < 2 ? "Passenger" : "Passengers"}
          </span>
          {window.ddSearchForm.formType !== "dashboard" && (
            <p
              className={`font-medium capitalize text-base ${
                window.ddSearchForm.formType === "dashboard"
                  ? "lg:text-xl"
                  : "lg:text-base"
              }`}
            >
              , {form.luggage} {form.luggage < 2 ? "Luggage" : "Luggages"}
            </p>
          )}
        </div>
        {window.ddSearchForm.formType === "dashboard" && (
          <p className="block text-xs capitalize">
            {form.luggage} {form.luggage < 2 ? "Luggage" : "Luggages"}
          </p>
        )}
      </div>
      {/* Dropdown */}
      <div
        ref={passengerDropdownRef}
        className={`fixed top-0 left-0 p-3 w-screen h-screen bg-white rounded-lg border w-full z-[999] lg:absolute lg:top-auto lg:left-auto lg:w-full lg:h-fit ${
          !showPassengerDropdown && "hidden"
        }`}
      >
        <div className="text-end">
          <button
            type="button"
            className="inline-block px-2 py-1 lg:hidden"
            onClick={() => setShowPassengerDropdown(!showPassengerDropdown)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        {/* Travelers */}
        <div className="mb-3">
          <p className="font-medium border-b pb-2 mb-2">Travelers</p>
          <div className="flex justify-between mb-2">
            <p>Adults</p>
            <div className="flex gap-2">
              <button
                type="button"
                className={`px-2 rounded ${
                  form.adults < 2 ? "bg-gray-100" : "bg-primary-100"
                }`}
                disabled={form.adults < 2}
                onClick={() => onChangeValues("adults", form.adults - 1)}
              >
                -
              </button>
              <p className="w-6 text-center">{form.adults}</p>
              <button
                type="button"
                className="bg-primary-100 px-2 rounded"
                onClick={() => onChangeValues("adults", form.adults + 1)}
              >
                +
              </button>
            </div>
          </div>
          <div className="flex justify-between mb-2">
            <p>Children</p>
            <div className="flex gap-2">
              <button
                type="button"
                className={`px-2 rounded ${
                  form.children < 1 ? "bg-gray-100" : "bg-primary-100"
                }`}
                disabled={form.children < 1}
                onClick={() => onChangeValues("children", form.children - 1)}
              >
                -
              </button>
              <p className="w-6 text-center">{form.children}</p>
              <button
                type="button"
                className="bg-primary-100 px-2 rounded"
                onClick={() => onChangeValues("children", form.children + 1)}
              >
                +
              </button>
            </div>
          </div>
          <p className="font-medium border-b pb-2 mb-2">Baggage</p>
          <div className="flex justify-between">
            <p>Luggage</p>
            <div className="flex gap-2">
              <button
                type="button"
                className={`px-2 rounded ${
                  form.luggage < 1 ? "bg-gray-100" : "bg-primary-100"
                }`}
                disabled={form.luggage < 1}
                onClick={() => onChangeValues("luggage", form.luggage - 1)}
              >
                -
              </button>
              <p className="w-6 text-center">{form.luggage}</p>
              <button
                type="button"
                className="bg-primary-100 px-2 rounded"
                onClick={() => onChangeValues("luggage", form.luggage + 1)}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
