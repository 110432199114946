import React from "react";
import "./App.css";
import { SearchForm } from "./components";
function App() {
  // Set global variables attached to the window object
  // window.ddSearchForm = {
  //   flightActionURL: "#",
  //   hotelActionURL: "#",
  //   carActionURL: "#",
  //   insuranceActionURL: "#",
  //   flightServiceURL:
  //     "https://destinotravels.co.uk/FlightService.ashx?searchWord=",
  //   hotelServiceURL:
  //     "https://destinotravels.co.uk/HotelService.ashx?searchWord=",
  //   placesAPIKey: "AIzaSyAAMuJwRfBDlJnDfUFwQFgMPja4azvxrWw",
  //   formType: "dashboard",
  //   userId: "12345678",
  // };

  return (
    <section className="container">
      <SearchForm />
    </section>
  );
}
export default App;
