import React from "react";
import unixTimeToDate from "../../util/convertUnixToDate";
import DropdownSelector from "./DropdownSelector";
import DateRangeInput from "../DateRangeInput";
import LocationsSelector from "./LocationsSelector";
import { countries } from "../../util/countries";
import getDateOnly from "../../util/getDateOnly";
import Cookies from "../../util/cookies";

export default function Insurance() {
  const [form, setForm] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const [recentSearches, setRecentSearches] = React.useState([]);

  const countriesList = countries.map((item) => ({
    label: item.name,
    value: item.name,
  }));
  const coverageOpts = [
    { label: "Individual", value: "individual" },
    { label: "Family", value: "family" },
  ];
  const travelOpts = [
    { label: "International", value: "international" },
    { label: "Domestic", value: "domestic" },
    { label: "Student", value: "student" },
    { label: "Hajj & Umrah", value: "hajj-and-umrah" },
    { label: "Ziarat", value: "ziarat" },
  ];

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("data");
    if (myParam) {
      setForm(JSON.parse(myParam));
    } else {
      setForm({
        type: "salaam-takaful",
        coverageType: "",
        travelType: "",
        dob: new Date(),
        startDate: new Date(),
        endDate: unixTimeToDate(new Date().setDate(new Date().getDate() + 1)),
        origin: "",
        destination: "",
      });
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
    ) {
      setRecentSearches(
        JSON.parse(
          Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
        )?.insurance
      );
    }
  }, []);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (typeof form.dob === "string") {
      form.dob = getDateOnly(new Date(Date.parse(form.dob)));
    } else {
      form.dob = getDateOnly(form.dob);
    }
    if (typeof form.startDate === "string") {
      form.startDate = getDateOnly(new Date(Date.parse(form.startDate)));
    } else {
      form.startDate = getDateOnly(form.startDate);
    }
    if (typeof form.endDate === "string") {
      form.endDate = getDateOnly(new Date(Date.parse(form.endDate)));
    } else {
      form.endDate = getDateOnly(form.endDate);
    }
    window.location =
      window.ddSearchForm.insuranceActionURL + "?data=" + JSON.stringify(form);

    // save search data in cookies
    let temp =
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
        ? JSON.parse(
            Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
          )
        : { flight: [], hotel: [], car: [], insurance: [] };
    // check if the same object does not exist already
    if (
      temp.insurance &&
      !temp.insurance.some(
        (obj) => JSON.stringify(obj) === JSON.stringify(form)
      )
    ) {
      if (temp.insurance && temp.insurance.length > 2) {
        temp.insurance.splice(0, 1);
      }
      temp.insurance.push(form);
      Cookies.setCookie(
        `recentSearches-${window.ddSearchForm.userId}`,
        JSON.stringify(temp)
      );
    }
  };

  const onChangeValues = (key, value) => {
    if (key instanceof Array && value instanceof Array) {
      // if update more than one properties in form
      let temp = {};
      for (let i = 0; i < key.length; i++) {
        temp[key[i]] = value[i];
      }
      setForm(Object.assign({}, form, temp));
    } else {
      // if update just single property in form
      setForm(Object.assign({}, form, { [key]: value }));
    }
  };

  const renderTypesRadioButtons = () => {
    return (
      <div className="flex flex-wrap justify-start md:justify-end gap-2">
        <label className="cursor-pointer text-base font-medium">
          <input
            type="radio"
            name="type"
            value="salaam-takaful"
            checked={form.type === "salaam-takaful"}
            onChange={(e) => {
              if (e.target.checked) {
                onChangeValues("type", "salaam-takaful");
              }
            }}
            className="accent-primary"
          />{" "}
          Salaam Takaful
        </label>
        <label className="cursor-pointer text-base font-medium">
          <input
            type="radio"
            name="type"
            value="tpl"
            checked={form.type === "tpl"}
            onChange={(e) => {
              if (e.target.checked) {
                onChangeValues("type", "tpl");
              }
            }}
            className="accent-primary"
          />{" "}
          TPL
        </label>
      </div>
    );
  };

  const renderRecentSearches = () => {
    if (recentSearches.length < 1) {
      return null;
    }

    return (
      <div className="flex flex-wrap gap-2 items-center">
        {" "}
        {/* Use div instead of React.Fragment */}
        <span className="font-medium">Recent Searches</span>
        {recentSearches.length &&
          recentSearches.map((item, index) => {
            return (
              <button
                type="button"
                key={index}
                className="bg-primary text-white text-xs rounded-lg px-2 py-2"
                onClick={() => setForm(Object.assign({}, form, item))}
              >
                <span>{item.coverageType}</span>
                <i className="fa-solid fa-arrows-left-right mx-2"></i>
                <span>{item.travelType}</span>
              </button>
            );
          })}
        {recentSearches.length && (
          <button
            type="button"
            onClick={() => {
              let temp =
                Cookies.getCookie(
                  `recentSearches-${window.ddSearchForm.userId}`
                ) !== ""
                  ? JSON.parse(
                      Cookies.getCookie(
                        `recentSearches-${window.ddSearchForm.userId}`
                      )
                    )
                  : { flight: [], hotel: [], car: [], insurance: [] };
              temp.insurance = [];
              Cookies.setCookie(
                `recentSearches-${window.ddSearchForm.userId}`,
                JSON.stringify(temp)
              );
              setRecentSearches([]);
            }}
          >
            <i className="fa-solid fa-rotate-right text-primary"></i>
          </button>
        )}
      </div>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="flex flex-col gap-2 mt-2 lg:flex-row">
        {/* Coverage Type */}
        <DropdownSelector
          label="Coverage Type"
          formKey="coverageType"
          formValue={form.coverageType}
          onSelectValue={onChangeValues}
          options={coverageOpts}
          wrapperClass={form.type !== "tpl" ? "basis-3/12" : "basis-2/12"}
        />

        {/* Travel Type */}
        <DropdownSelector
          label="Travel Type"
          formKey="travelType"
          formValue={form.travelType}
          onSelectValue={onChangeValues}
          options={travelOpts}
          wrapperClass={form.type !== "tpl" ? "basis-3/12" : "basis-2/12"}
        />

        {/* DOB */}
        <DateRangeInput
          startLabel="Date of Birth"
          startKey="dob"
          startDate={form.dob}
          onSelectValue={onChangeValues}
          isRange={false}
          wrapperClass={form.type !== "tpl" && "basis-3/12"}
        />

        {/* Dates */}
        <DateRangeInput
          startLabel="Start Date"
          endLabel="End Date"
          startKey="startDate"
          endKey="endDate"
          startDate={form.startDate}
          endDate={form.endDate}
          onSelectValue={onChangeValues}
          isRange={true}
          wrapperClass={form.type !== "tpl" && "grow"}
          minDate={new Date()}
        />

        {/* Locations */}
        {form.type === "tpl" && (
          <LocationsSelector
            form={form}
            onChangeValues={onChangeValues}
            options={countriesList}
          />
        )}
      </div>
    );
  };

  return (
    !isLoading && (
      <div>
        <div className={`flex flex-col lg:flex-row justify-end`}>
          {renderTypesRadioButtons()}
        </div>
        {renderSearchForm()}
        <div className="text-center mt-6 mb-3">
          <button
            type="button"
            className="dd-button w-[180px]"
            onClick={onSearchSubmit}
          >
            Search
          </button>
        </div>
        {renderRecentSearches()}
      </div>
    )
  );
}
