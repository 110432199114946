import React from "react";
import LocationSelector from "./LocationSelector";
import DateRangeInput from "../DateRangeInput";
import TimeInput from "./TimeInput";
import PassengerSelector from "./PassengerSelector";
import Cookies from "../../util/cookies";

export default function Cars() {
  const [form, setForm] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const [recentSearches, setRecentSearches] = React.useState([]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get("data");
    if (myParam) {
      setForm(JSON.parse(myParam));
    } else {
      setForm({
        from: {
          name: "",
          address: "",
          lat: "",
          lng: "",
          types: "",
        },
        to: {
          name: "",
          address: "",
          lat: "",
          lng: "",
          types: "",
        },
        fromDate: new Date(),
        fromTime: new Date(),
        adults: 1,
        children: 0,
        luggage: 0,
      });
    }
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
    ) {
      setRecentSearches(
        JSON.parse(
          Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
        )?.car
      );
    }
  }, []);

  const onSearchSubmit = (e) => {
    e.preventDefault();
    window.location =
      window.ddSearchForm.carActionURL + "?data=" + JSON.stringify(form);

    // save search data in cookies
    let temp =
      Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`) !== ""
        ? JSON.parse(
            Cookies.getCookie(`recentSearches-${window.ddSearchForm.userId}`)
          )
        : { flight: [], hotel: [], car: [], insurance: [] };
    // check if the same object does not exist already
    if (
      temp.car &&
      !temp.car.some((obj) => JSON.stringify(obj) === JSON.stringify(form))
    ) {
      if (temp.car && temp.car.length > 2) {
        temp.car.splice(0, 1);
      }
      temp.car.push(form);
      Cookies.setCookie(
        `recentSearches-${window.ddSearchForm.userId}`,
        JSON.stringify(temp)
      );
    }
  };

  const onChangeValues = (key, value) => {
    if (key instanceof Array && value instanceof Array) {
      // if update more than one properties in form
      let temp = {};
      for (let i = 0; i < key.length; i++) {
        temp[key[i]] = value[i];
      }
      setForm(Object.assign({}, form, temp));
    } else {
      // if update just single property in form
      setForm(Object.assign({}, form, { [key]: value }));
    }
  };

  const renderRecentSearches = () => {
    if (recentSearches.length < 1) {
      return null;
    }

    return (
      <div className="flex flex-wrap gap-2 items-center">
        {" "}
        {/* Use div instead of React.Fragment */}
        <span className="font-medium">Recent Searches</span>
        {recentSearches.length &&
          recentSearches.map((item, index) => {
            return (
              <button
                type="button"
                key={index}
                className="bg-primary text-white text-xs rounded-lg px-2 py-2"
                onClick={() => setForm(Object.assign({}, form, item))}
              >
                <span>{item.from.name}</span>
                <i className="fa-solid fa-arrow-right-long mx-2"></i>
                <span>{item.to.name}</span>
              </button>
            );
          })}
        {recentSearches.length && (
          <button
            type="button"
            onClick={() => {
              let temp =
                Cookies.getCookie(
                  `recentSearches-${window.ddSearchForm.userId}`
                ) !== ""
                  ? JSON.parse(
                      Cookies.getCookie(
                        `recentSearches-${window.ddSearchForm.userId}`
                      )
                    )
                  : { flight: [], hotel: [], car: [], insurance: [] };
              temp.car = [];
              Cookies.setCookie(
                `recentSearches-${window.ddSearchForm.userId}`,
                JSON.stringify(temp)
              );
              setRecentSearches([]);
            }}
          >
            <i className="fa-solid fa-rotate-right text-primary"></i>
          </button>
        )}
      </div>
    );
  };

  const renderSearchForm = () => {
    return (
      <div className="flex flex-col gap-2 mt-2 lg:flex-row">
        {/* From */}
        <LocationSelector
          label="Pickup From"
          formKey="from"
          formValue={form.from}
          onSelectValue={onChangeValues}
        />

        {/* To */}
        <LocationSelector
          label="Drop-off To"
          formKey="to"
          formValue={form.to}
          onSelectValue={onChangeValues}
        />

        {/* Date */}
        <DateRangeInput
          startLabel="Pickup Date"
          startKey="fromDate"
          startDate={form.fromDate}
          onSelectValue={onChangeValues}
          isRange={false}
          minDate={new Date()}
        />

        {/* Time */}
        <TimeInput
          label="Pickup Time"
          formKey="fromTime"
          formValue={form.fromTime}
          onSelectValue={onChangeValues}
        />

        {/* Passengers */}
        <PassengerSelector form={form} onChangeValues={onChangeValues} />

        {/* Search Icon Button */}
        {window.ddSearchForm.formType !== "dashboard" && (
          <button className="dd-button" type="button" onClick={onSearchSubmit}>
            <i className="fa-solid fa-magnifying-glass text-xl"></i>
          </button>
        )}
      </div>
    );
  };

  return (
    !isLoading && (
      <div>
        {renderSearchForm()}
        {window.ddSearchForm.formType === "dashboard" && (
          <div className="text-center mt-6 mb-3">
            {" "}
            <button
              type="button"
              className="dd-button w-[180px]"
              onClick={onSearchSubmit}
            >
              Search
            </button>
          </div>
        )}
        {window.ddSearchForm.formType === "dashboard" && renderRecentSearches()}
      </div>
    )
  );
}
