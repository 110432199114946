export default function unixTimeToDate(unixTimestamp) {
  const dateObj = new Date(unixTimestamp); // convert to milliseconds
  let year = dateObj.getFullYear();
  let month = dateObj.getMonth() + 1; // add 1, since the first month is 0
  let day = dateObj.getDate();

  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  // return in a formatted string
  return new Date(`${year}-${month}-${day}`);
}
